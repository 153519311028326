<template>
  <div class="content-block-type-paiement">
    <div class="content-card-table-type-paiement">
      <v-data-table
        v-model="selectedTable"
        :headers="fields"
        :items="TypeReglement"
        class="table-setting"
        hide-default-footer
        :calculate-widths="false"
        :fixed-header="true"
        :items-per-page="perPage"
        hide-action
        :disable-sort="true"
        :no-data-text="
          LoadingReglement
            ? 'Chargement en cours ...'
            : `Il n'y a aucun enregistrement à afficher`
        "
      >
        <!--NAME -->
        <template v-slot:[`item.name`]="{ item }">
          <td class="custom-cell">{{ item.name }}</td>
        </template>
        <!--ACTIONS-->
        <template v-slot:[`item.Actions`]="{ item }">
          <td class="custom-cell">
            <div class="d-flex actions-style-type">
              <div
                class="bloc-icon-gestion mr-1"
                v-if="checkPermission('GTRHTMTR')"
                @click.prevent.stop="updateCurrentType(item)"
                title="Modifier Type paiement"
              >
                <font-awesome-icon icon="pencil-alt" />
              </div>
              <div
                v-if="checkPermission('GTRHTSTR')"
                class="bloc-icon-gestion mr-1"
                @click.prevent.stop="deleteCurrentType(item)"
                title="Supprimer Type paiement"
              >
                <font-awesome-icon icon="trash" />
              </div>
            </div>
          </td>
        </template>
      </v-data-table>
    </div>
    <div class="footer-style-table">
      <div class="text-center pagination-table ">
        <v-pagination
          v-model="page"
          :length="totalPages"
          @input="pagination"
          :total-visible="7"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify mr-2">
        <v-select
          v-model="perPage"
          :items="perPageList"
          @change="changePerPage"
          label="Eléments par page"
          color="#704ad1"
          item-color="#704ad1"
          outlined
          dense
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetY: true,
            closeOnClick: true,
            closeOnContentClick: true
          }"
        ></v-select>
      </div>
    </div>
    <!--MODAL UPDATE TYPE PAIEMENT-->
    <v-dialog
      v-model="updateTypePaiement"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card v-if="typePaiementToUpdate && typePaiementToUpdate != null">
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Modifier Type paiement </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalType('update', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <form class="form-add">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nom"
                  v-model="typePaiementToUpdate.name"
                  :persistent-placeholder="true"
                  :rules="rulesType"
                  :error-messages="errorMessagesType"
                  class="msg-error"
                  ref="nom"
                  color="#704ad1"
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
          </form>
          <div v-if="errorReglement" class="message-error-modal">
            <ul v-if="Array.isArray(errorReglement)" class="mb-0">
              <li v-for="(e, index) in errorReglement" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ errorReglement }}</div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleupdateTypeReglement"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalType('update', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL DELETE TYPE PAIEMENT-->
    <v-dialog
      v-model="deleteTypePaiement"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Supprimer Type paiement </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalType('delete', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <div class="mt-4 ml-3 text-center">
            <p>
              Êtes-vous sur de vouloir supprimer ce type du paiement
              <span class="bold-text">
                {{
                  typePaiementToDelete && typePaiementToDelete.name
                    ? typePaiementToDelete.name
                    : ''
                }}</span
              >
              ?
            </p>
          </div>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)" class="mb-0">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div class="message-error-modal" v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleDeleteType"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalType('delete', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Gestion-type-reglement',
  data() {
    return {
      selectedTable: [],
      rulesType: [v => !!v || 'Nom type paiement est obligatoire.'],
      errorMessagesType: [],
      updateTypePaiement: false,
      deleteTypePaiement: false,
      type: null,
      error: null,
      loading: false,
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      fields: [
        {
          value: 'name',
          text: 'Type Paiement',
          thStyle: { width: '100%' }
        },
        { value: 'Actions', text: 'Actions', width: '40px !important' }
      ],
      typePaiementToDelete: null,
      typePaiementToUpdate: null,
      oldTypePaiement: null
    };
  },
  computed: {
    ...mapGetters([
      'TypeReglement',
      'errorReglement',
      'checkPermission',
      'rowReglement',
      'LoadingReglement'
    ]),
    totalPages() {
      if (this.rowReglement) {
        return Math.ceil(this.rowReglement / this.perPage);
      }
      return this.rowReglement;
    }
  },
  methods: {
    ...mapActions([
      'getAllTypesReglement',
      'deleteTypeReglement',
      'updateTypeReglement',
      'resetMutations'
    ]),
    handleModalType(method, action) {
      if (method == 'update') {
        if (action == 'show') {
          this.updateTypePaiement = true;
        } else {
          this.updateTypePaiement = false;
          this.resetModal();
          this.$refs.nom.reset();
          this.errorMessagesType = [];
        }
      }

      if (method == 'delete') {
        if (action == 'show') {
          this.deleteTypePaiement = true;
        } else {
          this.deleteTypePaiement = false;
          this.resetModal();
        }
      }
    },
    async changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    async handleFilter() {
      await this.getAllTypesReglement({
        per_page: this.perPage,
        page: this.page
      });
    },
    async pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    resetModal() {
      this.type = null;
      this.error = null;
      this.loading = false;
    },
    deleteCurrentType(data) {
      this.typePaiementToDelete = data;
      this.handleModalType('delete', 'show');
    },

    async handleDeleteType() {
      this.loading = true;
      const response = await this.deleteTypeReglement(
        this.typePaiementToDelete
      );
      if (response) {
        this.loading = false;
        this.handleModalType('delete', 'hide');
      } else {
        this.loading = false;
      }
    },
    updateCurrentType(data) {
      this.typePaiementToUpdate = { ...data };
      this.oldTypePaiement = data;
      this.handleModalType('update', 'show');
    },
    async handleupdateTypeReglement() {
      this.loading = true;
      if (this.$refs.nom.validate() == false) {
        this.errorMessagesType = this.$refs.nom.errorBucket;
        this.loading = false;
      } else {
        const response = await this.updateTypeReglement({
          typeReglementToUpdate: this.typePaiementToUpdate,
          oldTypeReglement: this.oldTypePaiement
        });
        if (response) {
          this.loading = false;
          this.handleModalType('update', 'hide');
        } else {
          this.loading = false;
          this.error = this.errorReglement;
        }
      }
    }
  },
  async mounted() {
    this.handleFilter();
  }
};
</script>
<style lang="scss" scoped>
.content-card-table-type-paiement {
  background-color: #fff;
  border-top: 0px;
  padding: 8px 5px 0px 5px;
  height: calc(100vh - 286px);
}
.content-block-type-paiement {
  height: calc(100vh - 250px);
}
.actions-style-type {
  position: relative;
  margin: auto;
  justify-content: center;
}
</style>
<style lang="scss">
.content-card-table-type-paiement {
  .table-setting .v-data-table__wrapper {
    height: calc(100vh - 293px);
  }
}
</style>
